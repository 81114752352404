body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 50px;

    input {
        margin: 10px 0;
        padding: 10px;
        width: 200px;
        width: 250px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

    button {
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;
    }

    .error {
        color: red;
        margin-top: 10px;
    }
}

.consensi-list {
    width: 100%;
    max-width: 800px;
    max-width: 1000px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;

    h2 {
        margin-bottom: 20px;
        color: #007bff;
    }

    .search-bar {
        margin-bottom: 20px;
        padding: 10px;
        width: 100%;
        max-width: 400px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            border: 1px solid #ddd;
            padding: 8px;
            padding: 12px;
            text-align: left;
        }

        th {
            background-color: #f2f2f2;
            background-color: #007bff;
            color: #fff;
        }

        tr:nth-child(even) {
            background-color: #f9f9f9;
        }

        tr:hover {
            background-color: #f1f1f1;
        }
    }
}