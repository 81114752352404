.accept-terms {
    display: flex;
    flex-direction: column;
    height: 100svh;
    width: 100svw;

    .tab {
        position: relative;
        flex: 1;
        width: 100%;
        height: 100%;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        background-color: white;
        overflow: hidden;
    }

    .fiscal-code-input {
        input {
            text-transform: uppercase !important;
        }
    }

    .privacy-policy {
        position: relative;
        padding: 32px;
        padding-bottom: 102px;
        box-sizing: border-box;
        overflow: auto;
        height: 100%;

        .document {
            line-height: 1.25;
            text-align: start;

            h1,
            h2 {
                font-size: 1.5em;
                line-height: 1;
                margin-bottom: 1em;
            }

            ul,
            ol {
                margin: 0;
                padding-left: 1em;
                margin-top: 0.75em;
                margin-bottom: 0.75em;
            }

            li {
                padding-top: 0.75em;
                padding-bottom: 0.75em;
                box-sizing: border-box;
            }

            .center {
                text-align: center;
            }

            .title {
                margin-bottom: 2.5em;
            }
        }

        @media screen and (max-width: 1023px) {
            .agency-logo-container {
                display: none;
            }

            .document {
                font-size: 12px;
            }
        }

        .button-container {
            position: fixed;
            bottom: 32px;
            left: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
        }

        .fab-container {
            position: fixed;
            bottom: 32px;
            left: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
        }

        .button-container,
        .fab-container {
            opacity: 0;
            transform: translateY(32px);
            transition: all 250ms ease;

            &.visible {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .user-info {
        position: relative;
        padding: 32px;
        box-sizing: border-box;
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .title {
            p {
                margin-top: 8px;
            }
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 8px;
            width: 100%;

            .input {
                flex: 1;
            }
        }

        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            width: 100%;
        }

        .checkbox-list-item {
            display: flex;
            flex-direction: row;
            column-gap: 16px;
            text-align: start;
            align-items: center;
        }

        .fiscal-code-input input {
            text-transform: uppercase;
        }
    }
}