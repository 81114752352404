header {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .agency-logo {
        height: 40px;
    }
}