.send-otp {
    display: flex;
    flex-direction: column;
    height: 100svh;

    .page-content {
        position: relative;
        flex: 1;

        .container {
            width: 100%;
            height: 100%;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            background-color: white;

            box-sizing: border-box;
            padding: 32px;

            .input-container {
                width: 100%;
                margin-top: 32px;

                .input,
                .input .box {
                    width: 100%;
                }
            }

            .phone-container {
                display: flex;
                flex-direction: row;
                column-gap: 8px;
                margin-top: 16px;

                .input:nth-child(1) {
                    flex: 1;
                }

                .input:nth-child(2) {
                    flex: 3;
                }
            }
        }

        .button-container {
            position: fixed;
            bottom: 32px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}